import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios from '../../helpers/interceptors';
import { IReduxCoursesState } from '../reducers/course-reducer';
import globalAxios from 'axios';
import axiosInstance from '../../helpers/interceptors';
import { fetchData } from '../../base/functions/Functions';
export enum EReduxActionTypes {
  CREATE_COURSE = 'CREATE_COURSE',
  UPDATE_COURSE = 'UPDATE_COURSE',
  CREATE_CHAPTER = 'CREATE_CHAPTER',
  UPDATE_CHAPTER = 'UPDATE_CHAPTER',
  DELETE_CHAPTER = 'DELETE_CHAPTER',
  CLEAR_FORM_COURSE = 'CLEAR_FORM_COURSE',
  GET_COURSES_DIRECTOR = 'GET_COURSES_DIRECTOR',
  GET_COURSES_PENDING_DIRECTOR = 'GET_COURSES_PENDING_DIRECTOR',
  GET_MY_COURSES = 'GET_MY_COURSES',
  UPDATE_INDEX_CHAPTERS = 'UPDATE_INDEX_CHAPTERS',
  GET_CHAPTER = 'GET_CHAPTER',
  GET_TEST = 'GET_TEST',
  CLEAR_CHAPTER = 'CLEAR_CHAPTER',
  GET_COURSE_CHAPTERS = 'GET_COURSE_CHAPTERS',
  AFFECTATION_PROF = 'AFFECTATION_PROF',
  UPLOAD_IMAGE_FILE = 'UPLOAD_IMAGE_FILE',
  GET_LINK_UPLOAD_VIDEO = 'GET_LINK_UPLOAD_VIDEO',
  UPLOAD_VIDEO_COURSE = 'UPLOAD_VIDEO_COURSE',
  GET_COURSE = 'GET_COURSE',
  GET_MY_STUDENTS_BY_COURSE = 'GET_MY_STUDENTS_BY_COURSE',
  GET_STUDENTS_DIRECTOR = 'GET_STUDENTS_DIRECTOR',
  UPDATE_COURSE_STATUS = 'UPDATE_COURSE_STATUS',
  PUBLISH_COURSE = 'PUBLISH_COURSE',
  CREATE_REGISTRATION = 'CREATE_REGISTRATION',
  GET_REGISTRATION_FORM = 'GET_REGISTRATION_FORM',
  CLEAR_REGISTRATION_FORM = 'CLEAR_REGISTRATION_FORM',
  CREATE_TEST = 'CREATE_TEST',
  CREATE_APPLICATION_FILE = 'CREATE_APPLICATION_FILE',
  GET_APPLICATION_FILE = 'GET_APPLICATION_FILE',
  CLEAR_APPLICATION_FILE = 'CLEAR_APPLICATION_FILE',
  CLEAR_FOLLOW = 'CLEAR_FOLLOW',
  CREATE_FOLLOW = 'CREATE_FOLLOW',
  GET_FOLLOW_BY_ID = 'GET_FOLLOW_BY_ID',
  UPDATE_INDEX_TESTS = 'UPDATE_INDEX_TESTS',
  UPDATE_TEST = 'UPDATE_TEST',
  GET_COURSE_TESTS = 'GET_COURSE_TESTS',
  CLEAR_FORM_TEST = 'CLEAR_FORM_TEST',
  GET_STUDENT_BY_ID = 'GET_STUDENT_BY_ID',
  AFFECT_OTHER_PROF = 'AFFECT_OTHER_PROF',
  REINSTATE_PROF = 'REINSTATE_PROF',
  GET_COURSE_FORM = 'GET_COURSE_FORM',
  SET_ACTUAL_TAB_COURSE = 'SET_ACTUAL_TAB_COURSE',
  SET_ACTUAL_TAB_PROGRESS = 'SET_ACTUAL_TAB_PROGRESS',
  SET_ACTUAL_TAB_COURSE_LISTE = 'SET_ACTUAL_TAB_COURSE_LISTE',
  SET_ACTUAL_TAB_COURSE_PREVIEW = 'SET_ACTUAL_TAB_COURSE_PREVIEW',
  DELETE_REGISTRATION = 'DELETE_REGISTRATION',
  PUBLISH_COURSE_OFFER_INIT = 'PUBLISH_COURSE_OFFER_INIT',
  SAVE_OFFER_PAYMENT_INFO = "SAVE_OFFER_PAYMENT_INFO",
  CLEAR_OFFER_PAYMENT_INFO = "CLEAR_OFFER_PAYMENT_INFO",
  CHECK_FINAL_EXAM = "CHECK_FINAL_EXAM",
  LOADING_MY_COURSES = "LOADING_MY_COURSES",
  CHANGE_STYLE_CARD = "CHANGE_STYLE_CARD",
  CLEAR_ALL_MY_COURSES = "CLEAR_ALL_MY_COURSES",
  LOADING_ALL_COURSES = "LOADING_ALL_COURSES",
  SET_TAB_ALL_COURSES = "SET_TAB_ALL_COURSES",
  UPDATE_SHOW = "UPDATE_SHOW",
  TYPE_FORM_CONTENT_COURSE = "TYPE_FORM_CONTENT_COURSE",
  SET_INDEX_CONTENT = "SET_INDEX_CONTENT",
  GET_OFFER_PRICE_COURSE = "GET_OFFER_PRICE_COURSE",
  SEND_IP_ADDRESS_COURSE = "SEND_IP_ADDRESS_COURSE",
  CHECK_FOLLOW_STUDENT_COURSE = "CHECK_FOLLOW_STUDENT_COURSE",
  GET_CONTENT = "GET_CONTENT",
  GET_PATH_CONTENT = 'GET_PATH_CONTENT',
  GET_SESSION_CONTENT = 'GET_SESSION_CONTENT',
  DELETE_EXAM = "DELETE_EXAM",
  CREATE_OR_UPDATE = "CREATE_OR_UPDATE",

  GET_ACTIVE_COURSES = 'GET_ACTIVE_COURSES',
  UPDATE_TRAINING = 'UPDATE_TRAINING',
  SEND_MY_REQUEST = 'SEND_MY_REQUEST',
  PATCH_GROUP = 'PATCH_GROUP',
  SET_COURSE = 'SET_COURSE',
  SET_TRAINING = 'SET_TRAINING',
  GET_COURSES_LENGTH = 'GET_COURSES_LENGTH',
  GET_COURSES_PENDING_LENGTH = 'GET_COURSES_PENDING_LENGTH',
  GET_COURSES_PUBLISHED_LENGTH = 'GET_COURSES_PUBLISHED_LENGTH',
  LOADING_PENDING_COURSES = 'LOADING_PENDING_COURSES',
  LOADING_COURSES_SCROLL = 'LOADING_COURSES_SCROLL',
  LOADING_PENDING_COURSES_SCROLL = 'LOADING_PENDING_COURSES_SCROLL',
  UPDATE_COURSES_LISTS = 'UPDATE_COURSES_LISTS',
  LOADING_FOLLOWS_SCROLL = 'LOADING_FOLLOWS_SCROLL',
  GET_COURSES_ONLINE_DIRECTOR = 'GET_COURSES_ONLINE_DIRECTOR',
  GET_COURSES_OTHER_DIRECTOR = 'GET_COURSES_OTHER_DIRECTOR',
  LOADING_ONLINE_COURSES = 'LOADING_ONLINE_COURSES',
  GET_COURSES_OTHER_LENGTH = "GET_COURSES_OTHER_LENGTH",
  LOADING_ONLINE_COURSES_SCROLL = 'LOADING_ONLINE_COURSES_SCROLL',
  LOADING_OTHER_COURSES = 'LOADING_OTHER_COURSES',
  LOADING_OTHER_COURSES_SCROLL = 'LOADING_OTHER_COURSES_SCROLL',
  GET_COURSE_SUBTITLE_VIMEO_UPLOADED_SUCCESS = 'GET_COURSE_SUBTITLE_VIMEO_UPLOADED_SUCCESS',
  GET_COURSE_SUBTITLE_VIMEO_UPLOADED_FAILED = 'GET_COURSE_SUBTITLE_VIMEO_UPLOADED_FAILED',
  GET_COURSE_SUBTITLE_VIMEO_UPLOADED_LOADING = 'GET_COURSE_SUBTITLE_VIMEO_UPLOADED_LOADING',
  CREATE_OFFER_PRICING = 'CREATE_OFFER_PRICING',
  UPDATE_OFFER_PRICING = 'UPDATE_OFFER_PRICING',
  GET_OFFER_PRICING = 'GET_OFFER_PRICING',
  GET_DETAILS_SUBSCRIPTIONS = 'GET_DETAILS_SUBSCRIPTIONS',
  FIND_GROUPS='FIND_GROUPS',
  GET_FOLLOW_BY_FILTER='GET_FOLLOW_BY_FILTER',
  CLEAR_TARIFICATION='CLEAR_TARIFICATION',
  UPLOAD_CHAPTER_LOADER='UPLOAD_CHAPTER_LOADER',
  CLEAR_UPLOAD_CHAPTER_LOADER='CLEAR_UPLOAD_CHAPTER_LOADER'
}

interface IReduxBaseAction {
  type: EReduxActionTypes;
}
interface IReduxBaseAction {
  type: EReduxActionTypes;
}



export interface IReduxGetCourseSubtitleAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSE_SUBTITLE_VIMEO_UPLOADED_SUCCESS | EReduxActionTypes.GET_COURSE_SUBTITLE_VIMEO_UPLOADED_FAILED | EReduxActionTypes.GET_COURSE_SUBTITLE_VIMEO_UPLOADED_LOADING
  data?: any;
}
export interface IReduxFindGroupsAction extends IReduxBaseAction {
  type: EReduxActionTypes.FIND_GROUPS
  data?: any;
}
export interface IReduxGetFollowByFilterAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_FOLLOW_BY_FILTER
  data?: any;
}
export interface IReduxLoadingFollowsScrollAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_FOLLOWS_SCROLL
  data: any;
  pageNumber: any
  scrollOrNot: any
}
export interface IReduxUpdateCoursesListsAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_COURSES_LISTS
  data: any;
  status: any
}
export interface IReduxGetDeatailsSubscriptionAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_DETAILS_SUBSCRIPTIONS
  data: any;

}
export interface IReduxTypeFormContentCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.TYPE_FORM_CONTENT_COURSE;
  data: any
}
export interface IReduxUpdateTrainingAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_TRAINING;
  data:any

}
export interface IReduxAffectationProfAction extends IReduxBaseAction {
  type: EReduxActionTypes.AFFECTATION_PROF;

}

export interface IReduxTypeCreateOfferPricingAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_OFFER_PRICING;
  data: any
}
export interface IReduxTypeUpdateOfferPricingAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_OFFER_PRICING;
  data: any
}

export interface IReduxTypeGetOfferPricingAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_OFFER_PRICING;
  data: any,
  actual: any
}
export interface IReduxTypeSendMyRequestAction extends IReduxBaseAction {
  type: EReduxActionTypes.SEND_MY_REQUEST;
  data: any
}
export interface IReduxTypeGetLengthCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSES_LENGTH;
  data: any
}


export interface IReduxTypeGetLengthPendingCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSES_PENDING_LENGTH;
  data: any
}

export interface IReduxTypeGetLengthOtherCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSES_OTHER_LENGTH;
  data: any
}


export interface IReduxTypeGetLengthPublishedCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSES_PUBLISHED_LENGTH;
  data: any
}
export interface IReduxTypegetCreateOrUpdateAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_OR_UPDATE;
  data: any
}
export interface IReduxTypeGetPathContentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_PATH_CONTENT;
  data: any
}
export interface IReduxTypeGetSessionContentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_SESSION_CONTENT;
  data: any
}
export interface IReduxTypeCheckFollowStudentCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHECK_FOLLOW_STUDENT_COURSE;
  data: any
}
export interface IReduxGetContentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CONTENT;
  data: any
}
export interface IReduxTypeSendIpAddressCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.SEND_IP_ADDRESS_COURSE;
}
export interface IReduxGetOfferCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_OFFER_PRICE_COURSE;
  data: any
}
export interface IReduxSetIndexTestCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_INDEX_CONTENT;
  data: any
}
export interface IReduxClearFormPaymentFormAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_OFFER_PAYMENT_INFO;
}
export interface IReduxUpdateShowAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_SHOW;
  data: any;
}
export interface IReduxChangeTabAllCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_TAB_ALL_COURSES;
  data: any
}
export interface IReduxLoadingAllCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_ALL_COURSES;
}

export interface IReduxLoadingScrollCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_COURSES_SCROLL;
}
export interface IReduxSaveFormPaymentInfoFormAction extends IReduxBaseAction {
  type: EReduxActionTypes.SAVE_OFFER_PAYMENT_INFO;
  data: any;
}
export interface IReduxGetCourseEmailAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSE_FORM;
  data: any;
}
export interface IReduxPublishCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.PUBLISH_COURSE;
  data: any;
}
export interface IReduxPublishCourseWithOfferInitAction extends IReduxBaseAction {
  type: EReduxActionTypes.PUBLISH_COURSE_OFFER_INIT;
  data: any;
}
export interface IReduxGetActiveCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_ACTIVE_COURSES;
  data: any;
}
export interface IReduxactivateDesactivateArchiveCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.PUBLISH_COURSE;
  data: any;
}
export interface IReduxGetMyCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_MY_COURSES | EReduxActionTypes.LOADING_MY_COURSES;
  data: any;
  pageNumber: undefined | number,
  scrollOrNot: undefined
}
export interface IReduxGetCourseTestsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSE_TESTS;
  data: any;
}
export interface IReduxGetLinkUploadVideoAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_LINK_UPLOAD_VIDEO;
  data: any;
}
export interface IReduxUploadVideoCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPLOAD_VIDEO_COURSE;
  data: any;
}
export interface IReduxGetMyCoursesProfessorAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_MY_COURSES | EReduxActionTypes.LOADING_MY_COURSES;
  data: any;
  pageNumber: undefined | number,
  scrollOrNot: undefined
}
export interface IReduxGetCoursesForDirectorAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSES_DIRECTOR | EReduxActionTypes.LOADING_ALL_COURSES | EReduxActionTypes.LOADING_COURSES_SCROLL;
  data: any;
  pageNumberAllCourses: any
  scrollOrNot: any
}

export interface IReduxGetPendingCoursesForDirectorAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSES_PENDING_DIRECTOR | EReduxActionTypes.LOADING_PENDING_COURSES | EReduxActionTypes.LOADING_PENDING_COURSES_SCROLL;
  data: any;
  pageNumberPending: any
  scrollOrNot: any
}


export interface IReduxGetOnlineCoursesForDirectorAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSES_ONLINE_DIRECTOR | EReduxActionTypes.LOADING_ONLINE_COURSES | EReduxActionTypes.LOADING_ONLINE_COURSES_SCROLL;
  data: any;
  pageNumberOnline: any
  scrollOrNot: any
}

export interface IReduxGetOtherCoursesForDirectorAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSES_OTHER_DIRECTOR | EReduxActionTypes.LOADING_OTHER_COURSES | EReduxActionTypes.LOADING_OTHER_COURSES_SCROLL;
  data: any;
  pageNumberOther: any
  scrollOrNot: any
}

export interface IReduxClearChapterAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_CHAPTER;
}
export interface IReduxClearFormCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_FORM_COURSE;
}
export interface IReduxUpdateCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_COURSE;
  data: any;
}
export interface IReduxCreateCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_COURSE;
  data: any;
}
export interface IReduxGetCourseChaptersAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSE_CHAPTERS;
  data: any;
}
export interface IReduxCreateChapterAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_CHAPTER;
  data: any;
}
export interface IReduxCreateExamAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_TEST;
  data: any;
}
export interface IReduxUpdateChapterAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_CHAPTER;
  data: any;
}
export interface IReduxUpdateTestAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_TEST;
  data: any;
}

export interface IReduxDeleteChapterAction extends IReduxBaseAction {
  type: EReduxActionTypes.DELETE_CHAPTER;
  data: any;
}
export interface IReduxUploadImageFileAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPLOAD_IMAGE_FILE;
  data: any;
}
export interface IReduxGetCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_COURSE;
  data: any;
}

export interface IReduxGetMyCoursesStudentAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_MY_COURSES | EReduxActionTypes.LOADING_MY_COURSES | EReduxActionTypes.LOADING_FOLLOWS_SCROLL;
  data: any;
  pageNumber: any
  scrollOrNot: any
}
export interface IReduxGetMyStudentsByCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_MY_STUDENTS_BY_COURSE;
  data: any;
}
export interface IReduxGetStudentForDirectorsAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_STUDENTS_DIRECTOR;
  data: any;
}
export interface IReduxUpdateCourseStatusAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_COURSE_STATUS;
  data: any;
}
export interface IReduxCreateRegistrationAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_REGISTRATION;
  data: any;
}
export interface IReduxGetRegistartionFormAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_REGISTRATION_FORM;
  data: any;
}
export interface IReduxGetApplicationFileAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_APPLICATION_FILE;
  data: any;
}
export interface IReduxClearRegistrationFormAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_REGISTRATION_FORM;
}

export interface IReduxCreateApplicationFileAction extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_APPLICATION_FILE;
  data: any;
}
export interface IReduxClearApplicationFileAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_APPLICATION_FILE
}
export interface IReduxClearFollowAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_FOLLOW
}
export interface IReduxCreateFollowActions extends IReduxBaseAction {
  type: EReduxActionTypes.CREATE_FOLLOW;
  data: any;
}
export interface IReduxGetFollowByIdActions extends IReduxBaseAction {
  type: EReduxActionTypes.GET_FOLLOW_BY_ID;
  data: any
}
export interface IReduxUpdateIndexChapterAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_INDEX_CHAPTERS;
  data: any;
}
export interface IReduxUpdateIndexTestAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPDATE_INDEX_TESTS;
  data: any;
}
export interface IReduxGetChapterAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_CHAPTER;
  data: any;
}
export interface IReduxGetExamAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_TEST;
  data: any;
}
export interface IReduxClearFormTestAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_FORM_TEST;
  data: any;
}
export interface IReduxGetStudentByIdAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_STUDENT_BY_ID;
  data: any;
}
export interface IReduxGetStudentInfoByIdAction extends IReduxBaseAction {
  type: EReduxActionTypes.GET_STUDENT_BY_ID;
  data: any;
}
export interface IReduxaffectOtherProfAction extends IReduxBaseAction {
  type: EReduxActionTypes.AFFECT_OTHER_PROF;
  data: any;
}
export interface IReduxreinstateProfAction extends IReduxBaseAction {
  type: EReduxActionTypes.REINSTATE_PROF;
  data: any;
}
export interface IReduxSetActualTabCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_ACTUAL_TAB_COURSE;
  data: any;
}
export interface IReduxSetActualTabProgressAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_ACTUAL_TAB_PROGRESS;
  data: any;
}
export interface IReduxSetActualTabCourseListeAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_ACTUAL_TAB_COURSE_LISTE;
  data: any;
}
export interface IReduxSetActualTabCoursePreviewAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_ACTUAL_TAB_COURSE_PREVIEW;
  data: any;
}
export interface IReduxDeleteRegistrationAction extends IReduxBaseAction {
  type: EReduxActionTypes.DELETE_REGISTRATION;
  data: any;
}

export interface IReduxCheckFinalExamAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHECK_FINAL_EXAM;
}

export interface IReduxLoadingMyCoursesAction extends IReduxBaseAction {
  type: EReduxActionTypes.LOADING_MY_COURSES;
  data: any;
}
export interface IReduxChangeStyleCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.CHANGE_STYLE_CARD;
  data: any;
}
export interface IReduxClearAllDataAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_ALL_MY_COURSES;
}
export interface IReduxDeleteExmAction extends IReduxBaseAction {
  type: EReduxActionTypes.DELETE_EXAM;
  data: any
}
export interface IReduxPatchGroupAction extends IReduxBaseAction {
  type: EReduxActionTypes.PATCH_GROUP;
  data: any
}
export interface IReduxSetCourseAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_COURSE;
  data: any
}
export interface IReduxSetTrainingAction extends IReduxBaseAction {
  type: EReduxActionTypes.SET_TRAINING;
  data: any
}

export interface IReduxClearTarificationFormAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_TARIFICATION
  
}

export interface IReduxUploadChapterLoaderAction extends IReduxBaseAction {
  type: EReduxActionTypes.UPLOAD_CHAPTER_LOADER
  data: any
}

export interface IReduxClearUploadChapterLoaderAction extends IReduxBaseAction {
  type: EReduxActionTypes.CLEAR_UPLOAD_CHAPTER_LOADER
  data: any
}

export function clearUploadChapterLoader(filename: string): ThunkAction<Promise<IReduxClearUploadChapterLoaderAction>, IReduxCoursesState, undefined, IReduxClearUploadChapterLoaderAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxClearUploadChapterLoaderAction>) => {
  
    return dispatch({
      type: EReduxActionTypes.CLEAR_UPLOAD_CHAPTER_LOADER,
      data: filename
    });
  }
}

export function uploadChapterLoader(filename:string): ThunkAction<Promise<IReduxUploadChapterLoaderAction>, IReduxCoursesState, undefined, IReduxUploadChapterLoaderAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUploadChapterLoaderAction>) => {
    return dispatch({
      type: EReduxActionTypes.UPLOAD_CHAPTER_LOADER,
      data: filename
    });
  }
}

export function clearTarification(): ThunkAction<Promise<IReduxClearTarificationFormAction>, IReduxCoursesState, undefined, IReduxClearTarificationFormAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxClearTarificationFormAction>) => {

    return dispatch({
      type: EReduxActionTypes.CLEAR_TARIFICATION,
    });
  }
}

export function updateCoursesLists(courseidId: number, status: any): ThunkAction<Promise<IReduxUpdateCoursesListsAction>, IReduxCoursesState, undefined, IReduxUpdateCoursesListsAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUpdateCoursesListsAction>) => {

    return dispatch({
      type: EReduxActionTypes.UPDATE_COURSES_LISTS,
      data: courseidId,
      status: status
    });
  };
}




export function setIndexContent(index: string): ThunkAction<Promise<IReduxSetIndexTestCourseAction>, IReduxCoursesState, undefined, IReduxSetIndexTestCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxSetIndexTestCourseAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_INDEX_CONTENT,
      data: index
    });
  }
}


export function getCoursesLength(filter: any): ThunkAction<Promise<IReduxTypeGetLengthCoursesAction>, IReduxCoursesState, undefined, IReduxTypeGetLengthCoursesAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeGetLengthCoursesAction>) => {
    let result = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}training/size`, filter).then(res => { return res.data; }).catch(err => console.error(err))
    return dispatch({
      type: EReduxActionTypes.GET_COURSES_LENGTH,
      data: result
    });
  }
}
export function updateTraining(filter: any): ThunkAction<Promise<IReduxUpdateTrainingAction>, IReduxCoursesState, undefined, IReduxUpdateTrainingAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUpdateTrainingAction>) => {
    let data =  await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}training/update`, filter).then(res => { return res.data; }).catch(err => console.error(err))

  
    return dispatch({
      type: EReduxActionTypes.UPDATE_TRAINING,
      data:data
    });
  }
}

export function getCoursesPendingLength(filter: any): ThunkAction<Promise<IReduxTypeGetLengthPendingCoursesAction>, IReduxCoursesState, undefined, IReduxTypeGetLengthPendingCoursesAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeGetLengthPendingCoursesAction>) => {
    let result = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}training/sizepending`, filter).then(res => { return res.data; }).catch(err => console.error(err))
    return dispatch({
      type: EReduxActionTypes.GET_COURSES_PENDING_LENGTH,
      data: result
    });
  }
}
export function getCoursesOtherLength(filter: any): ThunkAction<Promise<IReduxTypeGetLengthOtherCoursesAction>, IReduxCoursesState, undefined, IReduxTypeGetLengthOtherCoursesAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeGetLengthOtherCoursesAction>) => {
    let result = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}training/sizeother`, filter).then(res => { return res.data; }).catch(err => console.error(err))
    return dispatch({
      type: EReduxActionTypes.GET_COURSES_OTHER_LENGTH,
      data: result
    });
  }
}

export function getCoursesPublishedLength(filter: any): ThunkAction<Promise<IReduxTypeGetLengthPublishedCoursesAction>, IReduxCoursesState, undefined, IReduxTypeGetLengthPublishedCoursesAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeGetLengthPublishedCoursesAction>) => {
    let result = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}training/sizepublished`, filter).then(res => { return res.data; }).catch(err => console.error(err))
    return dispatch({
      type: EReduxActionTypes.GET_COURSES_PUBLISHED_LENGTH,
      data: result
    });
  }
}


export function getCreateOrUpdate(text: string): ThunkAction<Promise<IReduxTypegetCreateOrUpdateAction>, IReduxCoursesState, undefined, IReduxTypegetCreateOrUpdateAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypegetCreateOrUpdateAction>) => {

    return dispatch({
      type: EReduxActionTypes.CREATE_OR_UPDATE,
      data: text
    });
  }
}

export function sendIpAddressCourse(object: any): ThunkAction<Promise<IReduxTypeSendIpAddressCourseAction>, IReduxCoursesState, undefined, IReduxTypeSendIpAddressCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeSendIpAddressCourseAction>) => {
    const baseUrl3 = process.env.REACT_APP_BASE_URL2
    await axios.post(`${baseUrl3}visit/info`, object).catch(err => { return null });
    return dispatch({
      type: EReduxActionTypes.SEND_IP_ADDRESS_COURSE,
    });
  }
}
export function checkFollowStudentCourse(idCourse: number, idPerson: number, trainingType: string): ThunkAction<Promise<IReduxTypeCheckFollowStudentCourseAction>, IReduxCoursesState, undefined, IReduxTypeCheckFollowStudentCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeCheckFollowStudentCourseAction>) => {
    const result = await axios.get(`${process.env.REACT_APP_BASE_URL3}follow/history/${idCourse}/${idPerson}/${trainingType}`).then((response: any) => {
      return (!!response.data) ? true : false;
    }).catch(err => { return true })
    return dispatch({
      type: EReduxActionTypes.CHECK_FOLLOW_STUDENT_COURSE,
      data: result
    });
  }
}
export function getOfferCourse(index: number): ThunkAction<Promise<IReduxGetOfferCourseAction>, IReduxCoursesState, undefined, IReduxGetOfferCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetOfferCourseAction>) => {
    const baseUrl3 = process.env.REACT_APP_BASE_URL11
    let offerId = await axios.get(baseUrl3 + `offers/offerId/` + index).then(res => { return (res.data === "") ? null : res.data }).catch(err => { return null });
    let pricing = null;
    const baseUrl = process.env.REACT_APP_BASE_URL12;
    if (offerId !== null) {
      pricing = await axios.get(baseUrl + `static/` + offerId).then(res => { return res.data }).catch(err => { return null });
    }

    return dispatch({
      type: EReduxActionTypes.GET_OFFER_PRICE_COURSE,
      data: pricing
    });
  }
}



export function getContent(filter: any): ThunkAction<Promise<IReduxGetContentAction>, IReduxCoursesState, undefined, IReduxGetContentAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetContentAction>) => {
    let rateObj = {
      ids: [filter.trainingId]
    }
    const baseUrl3 = process.env.REACT_APP_BASE_URL14
    let training = await axios.post(baseUrl3 + `training/content/`, filter).then(async (resp: any) => {
      const prof = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/naturalpersonbyid/${resp.data.idProf}`).catch(() => { return { data: "" } })
      const school = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/legalpersonbyid/${resp.data.entityId}`).catch(() => { return { data: {} } })
      const rate = await axios.post(`${process.env.REACT_APP_BASE_URL8}feedback/rating`, rateObj).catch(err => { return { data: { rate: 2.5 } } })

      const result = await globalAxios.all([prof, school, rate]).then(globalAxios.spread((...responses) => {
        resp.data.professor = responses[0].data;
        resp.data.school = responses[1].data;
        resp.data.rate = responses[2].data;


        return resp.data;
      }))
      return result;
    })
    return dispatch({
      type: EReduxActionTypes.GET_CONTENT,
      data: training
    });
  }
}


export function getActiveCourses(filter: any): ThunkAction<Promise<IReduxGetActiveCoursesAction>, IReduxCoursesState, undefined, IReduxGetActiveCoursesAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetActiveCoursesAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let training = await axios.post(baseUrl + `courses/`, filter).then(async (resp: any) => {
      return resp.data
    })
    return dispatch({
      type: EReduxActionTypes.GET_ACTIVE_COURSES,
      data: training
    });
  }
}

export function findGroups(id: any): ThunkAction<Promise<IReduxFindGroupsAction>, IReduxCoursesState, undefined, IReduxFindGroupsAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxFindGroupsAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL2;
    let groups = await axios.get(baseUrl + `person/findGroups/`+id).then(async (resp: any) => {
      return resp.data
    })
    return dispatch({
      type: EReduxActionTypes.FIND_GROUPS,
      data: groups
    });
  }
}
export function setTypeFormContentCourse(actualTab: string): ThunkAction<Promise<IReduxTypeFormContentCourseAction>, IReduxCoursesState, undefined, IReduxTypeFormContentCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeFormContentCourseAction>) => {

    return dispatch({
      type: EReduxActionTypes.TYPE_FORM_CONTENT_COURSE,
      data: { type: actualTab }
    });
  }
}
export function changeTabAllCourses(actualTab: string): ThunkAction<Promise<IReduxChangeTabAllCoursesAction>, IReduxCoursesState, undefined, IReduxChangeTabAllCoursesAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxChangeTabAllCoursesAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_TAB_ALL_COURSES,
      data: { tab: actualTab }
    });
  }
}


export function updateShow(object: boolean): ThunkAction<Promise<IReduxUpdateShowAction>, IReduxCoursesState, undefined, IReduxUpdateShowAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUpdateShowAction>) => {

    return dispatch({
      type: EReduxActionTypes.UPDATE_SHOW,
      data: object
    });
  }
}
export function checkFinalExam(): ThunkAction<Promise<IReduxCheckFinalExamAction>, IReduxCoursesState, undefined, IReduxCheckFinalExamAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxCheckFinalExamAction>) => {

    return dispatch({
      type: EReduxActionTypes.CHECK_FINAL_EXAM,
    });
  }
}
export function clearAllData(): ThunkAction<Promise<IReduxClearAllDataAction>, IReduxCoursesState, undefined, IReduxClearAllDataAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxClearAllDataAction>) => {

    return dispatch({
      type: EReduxActionTypes.CLEAR_ALL_MY_COURSES,
    });
  }
}
export function changeStyleCardCourse(style: string): ThunkAction<Promise<IReduxChangeStyleCourseAction>, IReduxCoursesState, undefined, IReduxChangeStyleCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxChangeStyleCourseAction>) => {

    return dispatch({
      type: EReduxActionTypes.CHANGE_STYLE_CARD,
      data: style
    });
  }
}


export function getMyCourses(filter: any): ThunkAction<Promise<IReduxGetMyCoursesAction>, IReduxCoursesState, undefined, IReduxGetMyCoursesAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetMyCoursesAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_MY_COURSES, data: undefined, pageNumber: undefined, scrollOrNot: undefined })
    const myCourses = await axios.post(`${process.env.REACT_APP_BASE_URL14}training/catalog`, filter)
      .then((resp: any) => resp.data)
      .catch((e: any) => { return [] })

    return dispatch({
      type: EReduxActionTypes.GET_MY_COURSES,
      data: myCourses,
      pageNumber: undefined,
      scrollOrNot: undefined


    })
  }
}
export function getMyCoursesStudent(filter: any, action: any): ThunkAction<Promise<IReduxGetMyCoursesStudentAction>, IReduxCoursesState, undefined, IReduxGetMyCoursesStudentAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetMyCoursesStudentAction>) => {

    if (action === 'first time') {
      dispatch({ type: EReduxActionTypes.LOADING_MY_COURSES, data: undefined, pageNumber: undefined, scrollOrNot: undefined });
    }
    else {
      dispatch({ type: EReduxActionTypes.LOADING_FOLLOWS_SCROLL, data: undefined, pageNumber: undefined, scrollOrNot: undefined });
    }
    const myCourses = await axios.post(`${process.env.REACT_APP_BASE_URL14}training/filter`, filter)
      .then((resp: any) => resp.data)
      .catch((e: any) => { return [] })
    let Subject = myCourses?.filter((training: any) => {
      return training?.followUp?.statusList?.find((status: any) => {
        return new Date(status?.creationDate).getTime() === Math.max(...training.followUp?.statusList?.map((e: any) => new Date(e.creationDate)))
      })?.status === "CANDIDATE"
        || training.followUp?.statusList?.find((status: any) => {
          return new Date(status?.creationDate).getTime() === Math.max(...training.followUp?.statusList?.map((e: any) => new Date(e.creationDate)))
        })?.status === "REFUSED"
        || training?.followUp?.statusList?.find((status: any) => {
          return new Date(status?.creationDate).getTime() === Math.max(...training.followUp?.statusList?.map((e: any) => new Date(e.creationDate)))
        })?.status === "UNPAID"
    })

    let idsSubject = Subject.map((obj: any) => { return obj.followUp.id })
    let inputChat = {
      subject: idsSubject.map((id: any) => { return id + " CHAT ADMISSION" }),
      trie: 'recent',
      searchFilter: "",
      page: 0,
      chat: true,
      event: "ADMISSION_CHAT",
    }
    let listSubjects:any = []
    if(inputChat?.subject?.length!=0){
     axios.post(process.env.REACT_APP_BASE_URL10 + "chat/getAllPosts", inputChat)
     .then(res => { listSubjects= res.data })
     .catch(err => {
      return []
    })
  }

    let myCoursesFinal = myCourses?.map((obj: any) => {
      let statusFinal = obj.followUp.statusList.find((status: any) => { return new Date(status?.creationDate).getTime() === Math.max(...obj.followUp?.statusList?.map((e: any) => new Date(e.creationDate))) })

      if (statusFinal?.status === "CANDIDATE" || statusFinal?.status === "REFUSED" || statusFinal?.status === "UNPAID") {
        obj['listPosts'] = listSubjects.filter((post: any) => { return Number(post.subject.idSubject) === Number(obj.followUp.id) })
        return obj
      }
      return obj

    })
    let pageNumber = myCoursesFinal ? filter?.pageNumber + 1 : filter?.pageNumber

    return dispatch({
      type: EReduxActionTypes.GET_MY_COURSES,
      data: myCoursesFinal,
      pageNumber,
      scrollOrNot: action
    })
  }
}


export function getMyCoursesProfessor(entityId: number, entityType: string, professorId: number): ThunkAction<Promise<IReduxGetMyCoursesProfessorAction>, IReduxCoursesState, undefined, IReduxGetMyCoursesProfessorAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetMyCoursesProfessorAction>) => {
    dispatch({ type: EReduxActionTypes.LOADING_MY_COURSES, data: undefined, pageNumber: undefined, scrollOrNot: undefined })
    const myCourses = await axios.post(`${process.env.REACT_APP_BASE_URL}courses`, { entityId: entityId, entityType: 'SCHOOL', idProf: professorId })
      .then(async res => { return res.data }).catch(err => [])
    const listIdCours = myCourses?.map((obj: any) => { return obj.id });
    let rating: any
    if (listIdCours?.length !== 0) {
      rating = await axios.post(`${process.env.REACT_APP_BASE_URL8}feedback/rating`, { ids: listIdCours }).then((resp: any) => resp).catch(e => { return { data: [] } })
    }
    myCourses?.map((course: any) => {
      const rate = rating.data.find((rate: any) => Number(rate.idSubject) === Number(course.id))?.rating;
      course['rating'] = (rate) ? rate : 0;
      return course;
    })
    return dispatch({
      type: EReduxActionTypes.GET_MY_COURSES,
      data: myCourses,
      pageNumber: undefined,
      scrollOrNot: undefined
    })
  }
}
export function getLinkUploadVideo(size: number): ThunkAction<Promise<IReduxGetLinkUploadVideoAction>, IReduxCoursesState, undefined, IReduxGetLinkUploadVideoAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetLinkUploadVideoAction>) => {

    const link = await axios.post(`${process.env.REACT_APP_BASE_URL}getLinkVideo/vimeo/` + size, null)
      .then(res => {
        return res.data
      })
      .catch(err => console.error(err))
    return dispatch({
      type: EReduxActionTypes.GET_LINK_UPLOAD_VIDEO,
      data: link
    })
  }
}
export function setActualTabManageCourse(tab: string): ThunkAction<Promise<IReduxSetActualTabCourseAction>, IReduxCoursesState, undefined, IReduxSetActualTabCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxSetActualTabCourseAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_ACTUAL_TAB_COURSE,
      data: tab
    })
  }
}
export function setActualTabManageProgress(tab: string): ThunkAction<Promise<IReduxSetActualTabProgressAction>, IReduxCoursesState, undefined, IReduxSetActualTabProgressAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxSetActualTabProgressAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_ACTUAL_TAB_PROGRESS,
      data: tab
    })
  }
}
export function setActualTabManageCourseListe(tab: string): ThunkAction<Promise<IReduxSetActualTabCourseListeAction>, IReduxCoursesState, undefined, IReduxSetActualTabCourseListeAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxSetActualTabCourseListeAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_ACTUAL_TAB_COURSE_LISTE,
      data: tab
    })
  }
}
export function setActualTabManageCoursePreview(tab: string): ThunkAction<Promise<IReduxSetActualTabCoursePreviewAction>, IReduxCoursesState, undefined, IReduxSetActualTabCoursePreviewAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxSetActualTabCoursePreviewAction>) => {

    return dispatch({
      type: EReduxActionTypes.SET_ACTUAL_TAB_COURSE_PREVIEW,
      data: tab
    })
  }
}

export function UploadVideoCourse(size: number, path: string, name: string): ThunkAction<Promise<IReduxGetLinkUploadVideoAction>, IReduxCoursesState, undefined, IReduxGetLinkUploadVideoAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetLinkUploadVideoAction>) => {
    const link: any = await axios.post(`${process.env.REACT_APP_BASE_URL4}getLinkVideo/vimeo`,
      { size: size, path: path, name: name })
      .then(async (res: any) => {
        const prog: any = await axios.patch(res.data.linkToUploadVideo, path, {
          headers: {
            'Tus-Resumable': '1.0.0',
            'Upload-Offset': '0',
            'Content-Type': 'application/offset+octet-stream',
            'Accept': 'application/vnd.vimeo.*+json;version=3.4'
          },
          onUploadProgress: function (progressEvent) {
            const total = progressEvent.total || size;
            const loaded = progressEvent.loaded || 0;
            const progress = loaded / total * 100;
          }
        });
        return prog.data;

      }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.GET_LINK_UPLOAD_VIDEO,
      data: link
    })
  }
}



export function getCoursesForDirector(filter: any, Firstaction: string): ThunkAction<Promise<IReduxGetCoursesForDirectorAction>, IReduxCoursesState, undefined, IReduxGetCoursesForDirectorAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCoursesForDirectorAction>) => {

    if (Firstaction === 'yes') {
      dispatch({ type: EReduxActionTypes.LOADING_ALL_COURSES, data: undefined, pageNumberAllCourses: undefined, scrollOrNot: undefined })
    } else {
      dispatch({ type: EReduxActionTypes.LOADING_COURSES_SCROLL, data: undefined, pageNumberAllCourses: undefined, scrollOrNot: undefined })
    }
    let courses = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/allT`, filter).then(res => { return res.data; }).catch(err => {return []})
    let pageNumberAllCourses = filter?.pageNumber
    return dispatch({
      type: EReduxActionTypes.GET_COURSES_DIRECTOR,
      data: courses,
      pageNumberAllCourses,
      scrollOrNot: Firstaction
    })
  }
}

export function getPendingCoursesForDirector(filter: any, Firstaction: string): ThunkAction<Promise<IReduxGetPendingCoursesForDirectorAction>, IReduxCoursesState, undefined, IReduxGetPendingCoursesForDirectorAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetPendingCoursesForDirectorAction>) => {

    if (Firstaction === 'yes') {
      dispatch({ type: EReduxActionTypes.LOADING_PENDING_COURSES, data: undefined, pageNumberPending: undefined, scrollOrNot: undefined })
    } else {
      dispatch({ type: EReduxActionTypes.LOADING_PENDING_COURSES_SCROLL, data: undefined, pageNumberPending: undefined, scrollOrNot: undefined })
    }
    let courses = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/allTrainingPending`, filter).then(res => { return res.data; }).catch(err => {return []})
    let pageNumberPending = courses ? filter?.pageNumber + 1 : filter?.pageNumber
    return dispatch({
      type: EReduxActionTypes.GET_COURSES_PENDING_DIRECTOR,
      data: courses,
      pageNumberPending,
      scrollOrNot: Firstaction
    })
  }
}


export function getOnlineCoursesForDirector(filter: any, Firstaction: string): ThunkAction<Promise<IReduxGetOnlineCoursesForDirectorAction>, IReduxCoursesState, undefined, IReduxGetOnlineCoursesForDirectorAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetOnlineCoursesForDirectorAction>) => {

    if (Firstaction === 'yes') {
      dispatch({ type: EReduxActionTypes.LOADING_ONLINE_COURSES, data: undefined, pageNumberOnline: undefined, scrollOrNot: undefined })
    } else {
      dispatch({ type: EReduxActionTypes.LOADING_ONLINE_COURSES_SCROLL, data: undefined, pageNumberOnline: undefined, scrollOrNot: undefined })
    }
    let courses = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/allTrainingOnline`, filter).then(res => { return res.data; }).catch(err => {return []})
    let pageNumberOnline = filter?.pageNumber
    return dispatch({
      type: EReduxActionTypes.GET_COURSES_ONLINE_DIRECTOR,
      data: courses,
      pageNumberOnline,
      scrollOrNot: Firstaction
    })
  }
}
export function getOtherCoursesForDirector(filter: any, Firstaction: string): ThunkAction<Promise<IReduxGetOtherCoursesForDirectorAction>, IReduxCoursesState, undefined, IReduxGetOtherCoursesForDirectorAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetOtherCoursesForDirectorAction>) => {

    if (Firstaction === 'yes') {
      dispatch({ type: EReduxActionTypes.LOADING_OTHER_COURSES, data: undefined, pageNumberOther: undefined, scrollOrNot: undefined })
    } else {
      dispatch({ type: EReduxActionTypes.LOADING_OTHER_COURSES_SCROLL, data: undefined, pageNumberOther: undefined, scrollOrNot: undefined })
    }
    let courses = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL14}training/allTrainingOther`, filter).then(res => { return res.data; }).catch(err => {return []})

    let pageNumberOther = courses ? filter?.pageNumber + 1 : filter?.pageNumber
    return dispatch({
      type: EReduxActionTypes.GET_COURSES_OTHER_DIRECTOR,
      data: courses,
      pageNumberOther,
      scrollOrNot: Firstaction
    })
  }
}
export function clearFormCourse(): ThunkAction<Promise<IReduxClearFormCourseAction>, IReduxCoursesState, undefined, IReduxClearFormCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxClearFormCourseAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_FORM_COURSE,
    });
  };
}
export function createCourse(object: any, connectedUser: any): ThunkAction<Promise<IReduxCreateCourseAction>, IReduxCoursesState, undefined, IReduxCreateCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxCreateCourseAction>) => {
    if (object['level'] === null || object['level'] === undefined) { object['level'] = "BEGINNER" }
    if ((object['idProf'] === 0 || object['idProf'] === null) && connectedUser.role === "role_prof") { object['idProf'] = connectedUser.idPerson; object['creator'] = connectedUser.idPerson };
    const baseUrl = process.env.REACT_APP_BASE_URL;    
    const res: any = await axiosInstance.post(baseUrl + "courses/createOrUpdateCourse", object).then(response => { return response; }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.CREATE_COURSE,
      data: res?.data
    });
  };
}

export function updateCourse(level: string, idCourse: number): ThunkAction<Promise<IReduxUpdateCourseAction>, IReduxCoursesState, undefined, IReduxUpdateCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUpdateCourseAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const res: any = await axios.post(baseUrl + "courses/updateLevel/" + idCourse + "/" + level, null).then(response => { return response; }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.UPDATE_COURSE,
      data: res.data
    });
  };
}
export function createChapter(
  object: any, idCourse: number
): ThunkAction<Promise<IReduxCreateChapterAction>, IReduxCoursesState, undefined, IReduxCreateChapterAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxCreateChapterAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl2 = process.env.REACT_APP_BASE_URL4;
    const formData = new FormData();
    for (const file of object.files) {
      formData.append('file', file, file.name);
    }
    const resultat: any = await axios.post(baseUrl2 + "upload/file", formData).then(async (res: any) => {
      object['files'] = res.data.links;
      const result: any = await axios.post(baseUrl + "chapters/createChapter/" + idCourse, object).then(response => { return response; }).catch(err => console.error(err));
      return result.data;
    }).catch(error => console.error(error));
    return dispatch({
      type: EReduxActionTypes.CREATE_CHAPTER,
      data: resultat
    });
  };
}
export function affectOtherProf(idProf: any, idOtherProf: any, idDirector: any, object: any): ThunkAction<Promise<IReduxaffectOtherProfAction>, IReduxCoursesState, undefined, IReduxaffectOtherProfAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxaffectOtherProfAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const response: any = await axios.post(baseUrl + "courses/affectOtherProf/" + idProf + "/" + idOtherProf + "/" + object.idSchool, null).then(async (res: any) => {
      object['courses'] = res.data
      return res.data;
    }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.AFFECT_OTHER_PROF,
      data: response
    });
  };
}
export function reinstateProf(idProf: any, idDirector: any, object: any): ThunkAction<Promise<IReduxreinstateProfAction>, IReduxCoursesState, undefined, IReduxreinstateProfAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxreinstateProfAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const response: any = await axios.post(baseUrl + "courses/reinstateProf/" + idProf + "/" + object.idSchool, null).then(async (res: any) => {
      object['messageReinstateProf'] = res.data;
      const baseUrl2 = process.env.REACT_APP_BASE_URL2;
      await axios.post(baseUrl2 + 'person/reinstateProf/' + idProf + '/' + idDirector, object).then(resp => {
      }).catch(error => console.error(error));
      return res.data;
    }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.REINSTATE_PROF,
      data: response
    });
  };
}


export function createExam(object: any): ThunkAction<Promise<IReduxCreateExamAction>, IReduxCoursesState, undefined, IReduxCreateExamAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxCreateExamAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    const response: any = await axios.post(baseUrl + "exams/createOrUpdateExam", object).then(async (res: any) => {
      return res.data;
    }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.CREATE_TEST,
      data: response
    });
  };
}

export function updateChapter(object: any, idCourse: number): ThunkAction<Promise<IReduxUpdateChapterAction>, IReduxCoursesState, undefined, IReduxUpdateChapterAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUpdateChapterAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const baseUrl2 = process.env.REACT_APP_BASE_URL4;
    const formData = new FormData();
    let listFiles: any = [];
    for (const file of object.files) {
      if (file instanceof File) {
        formData.append('file', file, file.name);
      } else {
        listFiles.push(file);
      }
    }

    const response: any = await axios.post(baseUrl2 + "upload/file", formData).then(async (res: any) => {
      listFiles = listFiles.concat(res.data.links);
      object['files'] = listFiles;
      const result: any = await axios.post(baseUrl + "chapters/updateChapter/" + idCourse, object).then(resp => { return resp; }).catch(err => console.error(err));
      return result.data;
    }).catch(error => console.error(error));
    return dispatch({
      type: EReduxActionTypes.UPDATE_CHAPTER,
      data: response
    });
  };
}
export function updateExam(object: any, index: number): ThunkAction<Promise<IReduxUpdateTestAction>, IReduxCoursesState, undefined, IReduxUpdateTestAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUpdateTestAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    const response: any = await axios.post(baseUrl + "exams/createOrUpdateExam", object).then(async (res: any) => {
      return res.data;
    }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.UPDATE_TEST,
      data: { object: response, index: index }
    });
  };
}
export function deleteExam(idExam: number, index: number): ThunkAction<Promise<IReduxDeleteExmAction>, IReduxCoursesState, undefined, IReduxDeleteExmAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxDeleteExmAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    await axios.post(baseUrl + "exams/deleteExam/" + idExam, null).then(async (res: any) => {
      return res.data;
    }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.DELETE_EXAM,
      data: { index: index }
    });
  };
}
export function deleteChapter(id: number, idCourse: number): ThunkAction<Promise<IReduxDeleteChapterAction>, IReduxCoursesState, undefined, IReduxDeleteChapterAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxDeleteChapterAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    await axios.post(baseUrl + "chapters/deleteChapter/" + id + "/" + idCourse, null).then(async (res: any) => {
      return res.data;
    }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.DELETE_CHAPTER,
      data: id
    });
  };
}
export function updateIndexChapters(object: any, idCourse: number): ThunkAction<Promise<IReduxUpdateIndexChapterAction>, IReduxCoursesState, undefined, IReduxUpdateIndexChapterAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUpdateIndexChapterAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const res: any = await axios.post(baseUrl + "chapters/updateIndexChapters/" + idCourse, object).then(response => { return response; }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.UPDATE_INDEX_CHAPTERS,
      data: res.data
    });
  };
}
export function updateIndexExams(object: any, idCourse: number): ThunkAction<Promise<IReduxUpdateIndexTestAction>, IReduxCoursesState, undefined, IReduxUpdateIndexTestAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUpdateIndexTestAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    const res: any = await axios.post(baseUrl + "exams/updateIndexExams", object).then(response => {
      return response;
    }).catch(err => { return [] });
    return dispatch({
      type: EReduxActionTypes.UPDATE_INDEX_TESTS,
      data: res.data
    });
  };
}
export function clearFormTest(): ThunkAction<Promise<IReduxClearFormTestAction>, IReduxCoursesState, undefined, IReduxClearFormTestAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxClearFormTestAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_FORM_TEST,
      data: null
    });
  };
}
export function getChapter(
  chapter: any, indexChapter: any
): ThunkAction<Promise<IReduxGetChapterAction>, IReduxCoursesState, undefined, IReduxGetChapterAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetChapterAction>) => {
    return dispatch({
      type: EReduxActionTypes.GET_CHAPTER,
      data: { chapter: chapter, indexChapter: indexChapter }
    });
  };
}
export function getExam(
  test: any, indexTest: any
): ThunkAction<Promise<IReduxGetExamAction>, IReduxCoursesState, undefined, IReduxGetExamAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetExamAction>) => {
    return dispatch({
      type: EReduxActionTypes.GET_TEST,
      data: { test: test, indexTest: indexTest }
    });
  };
}
export function clearChapter(): ThunkAction<Promise<IReduxClearChapterAction>, IReduxCoursesState, undefined, IReduxClearChapterAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxClearChapterAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_CHAPTER,
    });
  };
}
export function getCourseChapters(id: number, course: any): ThunkAction<Promise<IReduxGetCourseChaptersAction>, IReduxCoursesState, undefined, IReduxGetCourseChaptersAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCourseChaptersAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const res: any = await axios.get(baseUrl + "chapters/getCourseChapters/" + id).then(response => { return response; }).catch(err => {return []});
    return dispatch({
      type: EReduxActionTypes.GET_COURSE_CHAPTERS,
      data: { course: course, orders: res.data }
    });
  };
}

export function getCourseTests(id: number): ThunkAction<Promise<IReduxGetCourseTestsAction>, IReduxCoursesState, undefined, IReduxGetCourseTestsAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCourseTestsAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL6;
    const res: any = await axios.get(baseUrl + "exams/getByCourseId/" + id).then(response => { return response; }).catch(err => {return []});
    return dispatch({
      type: EReduxActionTypes.GET_COURSE_TESTS,
      data: { listTest: res.data }
    });
  };
}
export function uploadImageCourse(file: any, object: any): ThunkAction<Promise<IReduxUploadImageFileAction>, IReduxCoursesState, undefined, IReduxUploadImageFileAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUploadImageFileAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL4;
    const baseUrl2 = process.env.REACT_APP_BASE_URL;

    const result: any = await axios.post(baseUrl + "upload/image", file).then(async (res) => {
      object['linkImg'] = res.data.link;
      const response = await axios.post(baseUrl2 + "courses/createOrUpdateCourse", object).then((resp: any) => { return resp; }).catch(error => console.error(error));
      return response.data;
    }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.UPLOAD_IMAGE_FILE,
      data: result
    });
  };
}
export function getCourse(courseId: number): ThunkAction<Promise<IReduxGetCourseAction>, IReduxCoursesState, undefined, IReduxGetCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCourseAction>) => {

    let rateObj = {
      ids: [courseId]
    }
    const course = await axios.get(`${process.env.REACT_APP_BASE_URL}courses/${courseId}`).then(async (response) => {
      return response.data
    }).then(async (data: any) => {
      const prof = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/naturalpersonbyid/${data.idProf}`).catch(() => { return { data: "" } })
      const school = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/legalpersonbyid/${data.entityId}`).catch(() => { return { data: {} } })
      const rate = await axios.post(`${process.env.REACT_APP_BASE_URL8}feedback/rating`, rateObj).catch(err => { return { data: { rate: 2.5 } } })
      const exams = await axios.get(`${process.env.REACT_APP_BASE_URL6}exams/getByCourseId/${courseId}`).catch(err => { return { data: [] } });
      return await globalAxios.all([prof, school, rate, exams]).then(globalAxios.spread((...responses) => {
        data.professor = responses[0].data;
        data.school = responses[1].data;
        data.rate = responses[2].data;
        data.exams = responses[3].data;
        //data.duration = data.duration + data.exams?.map((test: any) => test?.duration).reduce((a: number, b: number) => a + b, 0);
        return data;
      }))
    })
    return dispatch({
      type: EReduxActionTypes.GET_COURSE,
      data: course
    })
  }
}

export function getTraining(id: number): ThunkAction<Promise<IReduxGetCourseAction>, IReduxCoursesState, undefined, IReduxGetCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCourseAction>) => {
    const training = await axios.get(`${process.env.REACT_APP_BASE_URL14}training/${id}`).then(async (response) => {
      return Array.isArray(response.data) && response.data.length > 0 ? response.data[0] : null
    }).catch(() => { return { orders: [], exams: [], skills: [] } })
    const rate = await axios.post(`${process.env.REACT_APP_BASE_URL8}feedback/rating`,
      {
        ids: [id]
      }).catch(err => {
        return {
          data: {
            rate: 2.5
          }
        }

      })
    training.rate = rate.data
    return dispatch({
      type: EReduxActionTypes.GET_COURSE,
      data: training
    })
  }
}
export function getCourseFromEmail(courseId: number): ThunkAction<Promise<IReduxGetCourseEmailAction>, IReduxCoursesState, undefined, IReduxGetCourseEmailAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetCourseEmailAction>) => {
    const course = await axios.get(`${process.env.REACT_APP_BASE_URL}courses/${courseId}`).then(async (response) => {
      return response.data;


    }
    ).then(async (data: any) => {
      const school = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/legalpersonbyid/${data.entityId}`)
      const prof = await axios.get(`${process.env.REACT_APP_BASE_URL2}person/naturalpersonbyid/${data.idProf}`)
      return globalAxios.all([prof, school]).then(globalAxios.spread((...responses) => {
        data.school = responses[1].data
        data.professor = responses[0].data

        return data
      }))
    })
    return dispatch({
      type: EReduxActionTypes.GET_COURSE_FORM,
      data: course
    })
  }
}
export function getMyStudentsByCourse(courseId: number): ThunkAction<Promise<IReduxGetMyStudentsByCourseAction>, IReduxCoursesState, undefined, IReduxGetMyStudentsByCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetMyStudentsByCourseAction>) => {


    const Students = await axios.get(`${process.env.REACT_APP_BASE_URL3}follow/students/${courseId}`)
      .then(res => { return res.data; }).catch(err => {return []})
    return dispatch({
      type: EReduxActionTypes.GET_MY_STUDENTS_BY_COURSE,
      data: Students
    })
  }
}
export function getStudents(idSchool: any): ThunkAction<Promise<IReduxGetStudentForDirectorsAction>, IReduxCoursesState, undefined, IReduxGetStudentForDirectorsAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetStudentForDirectorsAction>) => {

    let baseURL = process.env.REACT_APP_BASE_URL3;
    const listStudents = axios.get(baseURL + "follow/allStudents/" + idSchool).then((response) => {
      return response.data
    })

    return dispatch({
      type: EReduxActionTypes.GET_STUDENTS_DIRECTOR,
      data: await listStudents
    })
  }
}
export function updateStatus(status: string, idCourse: number): ThunkAction<Promise<IReduxUpdateCourseStatusAction>, IReduxCoursesState, undefined, IReduxUpdateCourseStatusAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxUpdateCourseStatusAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL;
    const res: any = await axios.post(baseURL + "courses/updateStatus/" + idCourse + "/" + status)
      .then(response => { return response; }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.UPDATE_COURSE_STATUS,
      data: res?.data
    });
  };
}
export function publishCourse(value: string, idProf: number, idUser: number, obj: any): ThunkAction<Promise<IReduxPublishCourseAction>, IReduxCoursesState, undefined, IReduxPublishCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxPublishCourseAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL;
    obj['status'] = value;
    const result: any = await axios.post(baseURL + "courses/updateStatus/" + obj.idCours + "/" + `${(value === "REACTIVATE") ? "PUBLISHED" : obj.status}`, null)
      .then((res) => {
        const baseUrl2 = process.env.REACT_APP_BASE_URL2;
        axios.post(baseUrl2 + 'person/coursvalidation/' + idUser + '/' + idProf, obj).then(response => {
          
        }).catch(error => console.error(error));
        return res;
      }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.PUBLISH_COURSE,
      data: result?.data
    })
  }
}
export function publishCourseWithOfferInit(value: string, idProf: number, idUser: number, obj: any, offer: any): ThunkAction<Promise<IReduxPublishCourseWithOfferInitAction>, IReduxCoursesState, undefined, IReduxPublishCourseWithOfferInitAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxPublishCourseWithOfferInitAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL;
    let baseURL_offer = process.env.REACT_APP_BASE_URL11;
    obj['status'] = value;
    const result: any = await axios.post(baseURL + "courses/updateStatus/" + obj.idCours + "/" + obj.status, null)
      .then((res) => {
        const baseUrl2 = process.env.REACT_APP_BASE_URL2;
        axios.post(baseUrl2 + 'person/coursvalidation/' + idUser + '/' + idProf, obj).then(response => {
          
        }).catch(error => console.error(error));
        return res;
      }).catch(err => console.error(err));

    return dispatch({
      type: EReduxActionTypes.PUBLISH_COURSE_OFFER_INIT,
      data: { course: result.data, payement: null }
    })
  }
}
export function affectationProfMailing(obj: any): ThunkAction<Promise<IReduxAffectationProfAction>, IReduxCoursesState, undefined, IReduxAffectationProfAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxAffectationProfAction>) => {
    const baseUrl2 = process.env.REACT_APP_BASE_URL2;
    await axios.post(baseUrl2 + 'person/affectation/', obj).then(response => {
      
    }).catch(error => console.error(error));

    return dispatch({
      type: EReduxActionTypes.AFFECTATION_PROF,

    })
  }
}

export function createOrUpdateOfferCourse(offer: any): ThunkAction<Promise<IReduxTypeCreateOfferPricingAction>, IReduxCoursesState, undefined, IReduxTypeCreateOfferPricingAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeCreateOfferPricingAction>) => {

    let baseURL_offer = process.env.REACT_APP_BASE_URL11;

    let offerTraining = await axios.post(baseURL_offer + "offers/createOrUpdateOffer", offer)
      .then(response => {
        axios.get(baseURL_offer + "offers/getOffer/" + response?.data?.id)
          .then(response => { return response.data; })
          .catch(err => { return console.error(err) });
      })


    return dispatch({
      type: EReduxActionTypes.CREATE_OFFER_PRICING,
      data: offerTraining
    })
  }
}

export function getOfferPricingCourse(idOffer: any): ThunkAction<Promise<IReduxTypeGetOfferPricingAction>, IReduxCoursesState, undefined, IReduxTypeGetOfferPricingAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeGetOfferPricingAction>) => {

    let baseURL = process.env.REACT_APP_BASE_URL11;
    let offer: any = await axios.get(baseURL + "offers/getOffer/" + idOffer).then(response => { return response.data; }).catch(err => { return console.error(err) });
    return dispatch({
      type: EReduxActionTypes.GET_OFFER_PRICING,
      data: offer,
      actual: offer
    })
  }
}

export function getDetailsSubscriptions(object: any): ThunkAction<Promise<IReduxGetDeatailsSubscriptionAction>, IReduxCoursesState, undefined, IReduxGetDeatailsSubscriptionAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetDeatailsSubscriptionAction>) => {
    const baseUrl3 = process.env.REACT_APP_BASE_URL11
    let contract;
    let offerTraining;
    await axiosInstance.get(baseUrl3 + `offers/offerId/` + object?.id?.toString()).then(async (res: any) => {
      if (res.data) {
        await await axios.get(baseUrl3 + "offers/getOffer/" + res.data).then(async (resp: any) => {
          let filter = {
            personId: object?.idPerson,
            offerId: [resp?.data?.id]
          }
          await axiosInstance.post(process.env.REACT_APP_BASE_URL17 + `contracts/getContractsWithOfferIdAndPersonId`, filter).then(async resContract => {
            if (!!resContract && !!resContract?.data && resContract?.data?.length > 0) {
              contract = resContract?.data[0]              
              await axiosInstance.get(process.env.REACT_APP_BASE_URL12 + `pricing/pricing/` + resContract?.data[0]?.contractDetails?.pricingId).then(async resPricing => {

                offerTraining = resPricing?.data

              })
            }


          })
        })
      }
    })


    return dispatch({
      type: EReduxActionTypes.GET_DETAILS_SUBSCRIPTIONS,
      data: {
        contract: contract,
        offerTraining: offerTraining
      },

    })
  }
}



export function activateDesactivateArchiveCourse(status: string, idCourse: number, idProf: number, idUser: number, comment: string, titre: string, code: string, roleUser: string, actualStatus: string): ThunkAction<Promise<IReduxactivateDesactivateArchiveCourseAction>, IReduxCoursesState, undefined, IReduxactivateDesactivateArchiveCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxactivateDesactivateArchiveCourseAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL;
    const result: any = await axios.post(baseURL + "courses/updateStatus/" + idCourse + "/" + status, null)
      .then(res => {
        if (actualStatus !== "Draft") {
          const baseUrl2 = process.env.REACT_APP_BASE_URL2;
          axios.get(baseUrl2 + 'person/coursvalidation/' + idUser + '/' + idProf + '/' + comment + '/' + idCourse + '/' + status + '/' + titre + '/' + code + '/' + roleUser).then(response => {
            
          }).catch(error => console.error(error));
        }
        return res;
      }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.PUBLISH_COURSE,
      data: result.data
    })
  }
}

export function createRegistration(object: any, Training: any): ThunkAction<Promise<IReduxCreateRegistrationAction>, IReduxCoursesState, undefined, IReduxCreateRegistrationAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxCreateRegistrationAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL7;
    let registration: any

    if (Training.idRegistrationForm === undefined || Training.idRegistrationForm === null) {
      registration = await axios.post(baseUrl + "registration/createOrUpdateRegistrationForm/" + Training.id + "/" + Training.type, object)
        .then(async (res: any) => {
          Training['idRegistrationForm'] = res.data.id;
          if (Training.type === "COURSE") {
            await axios.post(process.env.REACT_APP_BASE_URL + "courses/createOrUpdateCourse", Training)
              
          } else {
            await axios.post(process.env.REACT_APP_BASE_URL + `${(Training.type === "PATH") ? "paths" : "sessions"}`, Training)
              
          }
          return res;
        })
        .catch(err => console.error(err));
    } else {
      registration = await axios.post(baseUrl + "registration/createOrUpdateRegistrationForm/" + Training.id + "/" + Training.type, object)
        .then(async (res: any) => { return res; }).catch(err => console.error(err));
    }

    return dispatch({
      type: EReduxActionTypes.CREATE_REGISTRATION,
      data: registration.data
    });
  };
}

export function getRegistrationForm(idRegistrationForm: any): ThunkAction<Promise<IReduxGetRegistartionFormAction>, IReduxCoursesState, undefined, IReduxGetRegistartionFormAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetRegistartionFormAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL7;
    const registrationForm = await axiosInstance.get(baseURL + "registration/getRegistration/" + idRegistrationForm).then((response) => {
      return response.data
    })

    return dispatch({
      type: EReduxActionTypes.GET_REGISTRATION_FORM,
      data: registrationForm
    })
  }
}
export function setRegistrationForm(object: any): ThunkAction<Promise<IReduxGetRegistartionFormAction>, IReduxCoursesState, undefined, IReduxGetRegistartionFormAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetRegistartionFormAction>) => {
    return dispatch({
      type: EReduxActionTypes.GET_REGISTRATION_FORM,
      data: object
    })
  }
}

export function clearRegistrationForm(): ThunkAction<Promise<IReduxClearRegistrationFormAction>, IReduxCoursesState, undefined, IReduxClearRegistrationFormAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxClearRegistrationFormAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_REGISTRATION_FORM,
    });
  };
}

export function createApplicationFile(object: any, idSchool: any): ThunkAction<Promise<IReduxCreateApplicationFileAction>, IReduxCoursesState, undefined, IReduxCreateApplicationFileAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxCreateApplicationFileAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL7;
    const baseUrl2 = process.env.REACT_APP_BASE_URL4;
    const formData = new FormData();
    for (let file of object.documents) {
      if (file.link instanceof File) {
        formData.append('file', file.link);
      }
    }

    const response: any = await axios.post(baseUrl2 + "upload/file", formData).then(async (res: any) => {

      const list = res.data.links.map((obj: any) => { return obj.link });
      let j = 0;
      object['documents'] = object.documents.map((obj: any) => {
        if (obj.link instanceof File) {
          obj.link = list[j];
          j++;
        }
        return obj;
      })

      return axios.post(baseUrl + "registration/createOrUpdateApplicationFile/" + idSchool, object).then(ress => { return ress; }).catch(err => console.error(err));
    }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.CREATE_APPLICATION_FILE,
      data: await response.data
    });
  };

}
export function getApplicationFile(idStudent: any, idRF: any): ThunkAction<Promise<IReduxGetApplicationFileAction>, IReduxCoursesState, undefined, IReduxGetApplicationFileAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetApplicationFileAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL7;
    const applicationFile = await axios.get(baseURL + "registration/application/" + idStudent + "/" + idRF).then((response) => {
      return response.data
    })
    return dispatch({
      type: EReduxActionTypes.GET_APPLICATION_FILE,
      data: await applicationFile
    })
  }
}
export function setApplicationFile(object: any): ThunkAction<Promise<IReduxGetApplicationFileAction>, IReduxCoursesState, undefined, IReduxGetApplicationFileAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetApplicationFileAction>) => {
    return dispatch({
      type: EReduxActionTypes.GET_APPLICATION_FILE,
      data: object
    })
  }
}
export function clearApplicationFile(): ThunkAction<Promise<IReduxClearApplicationFileAction>, IReduxCoursesState, undefined, IReduxClearApplicationFileAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxClearApplicationFileAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_APPLICATION_FILE,
    });
  };
}

export function clearFollow(): ThunkAction<Promise<IReduxClearFollowAction>, IReduxCoursesState, undefined, IReduxClearFollowAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxClearFollowAction>) => {
    return dispatch({
      type: EReduxActionTypes.CLEAR_FOLLOW,
    });
  };
}
export function createFollow(object: any, contractToCreate: any): ThunkAction<Promise<IReduxCreateFollowActions>, IReduxCoursesState, undefined, IReduxCreateFollowActions> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxCreateFollowActions>) => {

    let studentFollowPayload={
      "contactToCreate":contractToCreate,
      "studentFollowUpTraining":object
    }

    const listFollowUp=await fetchData('POST', `${process.env.REACT_APP_BASE_URL14}followUp/studentFollowTraining`, studentFollowPayload);
    

    return dispatch({
      type: EReduxActionTypes.CREATE_FOLLOW,
      data: listFollowUp
    });
  };
}
export function getFollow(idFollow: any): ThunkAction<Promise<IReduxGetFollowByIdActions>, IReduxCoursesState, undefined, IReduxGetFollowByIdActions> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetFollowByIdActions>) => {
    let baseURL = process.env.REACT_APP_BASE_URL3;
    const follow = await axios.get(baseURL + "follow/" + idFollow).then((response) => {
      return response.data
    })
    return dispatch({
      type: EReduxActionTypes.GET_FOLLOW_BY_ID,
      data: await follow
    });
  }
}

export function getStudentById(idStudent: any): ThunkAction<Promise<IReduxGetStudentByIdAction>, IReduxCoursesState, undefined, IReduxGetStudentByIdAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetStudentByIdAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL2;
    const studentInfo = await axios.get(baseURL + "person/naturalpersonbyid/" + idStudent).then((response) => {
      return response.data
    })

    return dispatch({
      type: EReduxActionTypes.GET_STUDENT_BY_ID,
      data: await studentInfo
    })
  }
}

export function getStudentInfoById(idStudent: any, idSchool: number): ThunkAction<Promise<IReduxGetStudentInfoByIdAction>, IReduxCoursesState, undefined, IReduxGetStudentInfoByIdAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetStudentInfoByIdAction>) => {
    let baseURL = process.env.REACT_APP_BASE_URL2;
    const studentInfo = await axios.post(baseURL + "person/student/" + idSchool, [idStudent]).then((response) => {
      return response.data
    })
    return dispatch({
      type: EReduxActionTypes.GET_STUDENT_BY_ID,
      data: await studentInfo[0]
    })
  }
}
export function deleteRegistration(idRegistration: number, Training: any): ThunkAction<Promise<IReduxDeleteRegistrationAction>, IReduxCoursesState, undefined, IReduxDeleteRegistrationAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxDeleteRegistrationAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL7;
    await axios.post(baseUrl + "registration/deleteRegistration/" + idRegistration, null).then(async (res: any) => {
      Training['idRegistrationForm'] = null;
      if (Training.type === "COURSE") {
        await axios.post(process.env.REACT_APP_BASE_URL + "courses/createOrUpdateCourse", Training)
         
      } else {
        await axios.post(process.env.REACT_APP_BASE_URL + `${(Training.type === "PATH") ? "paths" : "sessions"}`, Training)
          
      }

      return res.data;
    }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.DELETE_REGISTRATION,
      data: idRegistration
    });
  };
}

export function saveFormPaymentInfo(object: any): ThunkAction<Promise<IReduxSaveFormPaymentInfoFormAction>, IReduxCoursesState, undefined, IReduxSaveFormPaymentInfoFormAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxSaveFormPaymentInfoFormAction>) => {

    return dispatch({
      type: EReduxActionTypes.SAVE_OFFER_PAYMENT_INFO,
      data: object
    })
  }
}
export function clearFormPayment(): ThunkAction<Promise<IReduxClearFormPaymentFormAction>, IReduxCoursesState, undefined, IReduxClearFormPaymentFormAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxClearFormPaymentFormAction>) => {

    return dispatch({
      type: EReduxActionTypes.CLEAR_OFFER_PAYMENT_INFO,
    })
  }
}

export function getPathContent(idPath: number): ThunkAction<Promise<IReduxTypeGetPathContentAction>, IReduxCoursesState, undefined, IReduxTypeGetPathContentAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeGetPathContentAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let pathInfo = await axios.get(baseUrl + `paths/content/${idPath}`).then(res => { return res.data }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.GET_PATH_CONTENT,
      data: pathInfo
    });

  }
}


export function getSessionContent(idSession: number): ThunkAction<Promise<IReduxTypeGetSessionContentAction>, IReduxCoursesState, undefined, IReduxTypeGetSessionContentAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeGetSessionContentAction>) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let sessionInfo = await axios.get(baseUrl + `sessions/content/${idSession}`).then(res => { return res.data }).catch(err => console.error(err));
    return dispatch({
      type: EReduxActionTypes.GET_SESSION_CONTENT,
      data: sessionInfo
    });

  }
}


export function sendMyRequest(status: any, obj: any, idPerson: any, idProf: any): ThunkAction<Promise<IReduxTypeSendMyRequestAction>, IReduxCoursesState, undefined, IReduxTypeSendMyRequestAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxTypeSendMyRequestAction>) => {


    let newStatus = await axios.post(`${process.env.REACT_APP_BASE_URL3}follow/status`, status).then((response) => { return response.data }).catch(err => { console.error(err); })

    if (idPerson !== null) {
      await axios.post(`${process.env.REACT_APP_BASE_URL2}person/registrationValidation/` + idPerson + `/` + idProf, obj).then(res => {
      }).catch(error => console.error(error));
    }

    let data = { status: newStatus, idFollow: status.follow.id }
    return dispatch({
      type: EReduxActionTypes.SEND_MY_REQUEST,
      data: data
    });

  }
}

export function patchGroup(training: any): ThunkAction<Promise<IReduxPatchGroupAction>, IReduxCoursesState, undefined, IReduxPatchGroupAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxPatchGroupAction>) => {
    let groups = await axios.post(process.env.REACT_APP_BASE_URL + "training/groups", training).then(res => { return res.data }).catch(err => {return []});
    return dispatch({
      type: EReduxActionTypes.PATCH_GROUP,
      data: groups
    });
  }
}

export function getFollowByFilter(object: any): ThunkAction<Promise<IReduxGetFollowByFilterAction>, IReduxCoursesState, undefined, IReduxGetFollowByFilterAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxGetFollowByFilterAction>) => {
    let follows = await axios.post(process.env.REACT_APP_BASE_URL3 + "follow/filter", object).then(res => { return res.data });
    return dispatch({
      type: EReduxActionTypes.GET_FOLLOW_BY_FILTER,
      data: follows
    });
  }
}

export function setCourse(course: any): ThunkAction<Promise<IReduxSetCourseAction>, IReduxCoursesState, undefined, IReduxSetCourseAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxSetCourseAction>) => {
    return dispatch({
      type: EReduxActionTypes.SET_COURSE,
      data: course
    });
  }
}
export function setTraining(training: any): ThunkAction<Promise<IReduxSetTrainingAction>, IReduxCoursesState, undefined, IReduxSetTrainingAction> {
  return async (dispatch: ThunkDispatch<IReduxCoursesState, undefined, IReduxSetTrainingAction>) => {
    return dispatch({
      type: EReduxActionTypes.SET_TRAINING,
      data: training
    });
  }
}

export function getCourseSubtitleVimeoUploaded(idVideo: string): ThunkAction<Promise<IReduxGetCourseSubtitleAction>, IReduxCoursesState, undefined, IReduxGetCourseSubtitleAction> {
  return async (dispatch: ThunkDispatch<any, undefined, IReduxGetCourseSubtitleAction>) => {
    const res = await axios
      .get(
        `${process.env.REACT_APP_BASE_URL4
        }getAllSubtitles/${idVideo}`
      )
      .then((response) => {
        return response.data;
      });

    if (!res) {
      return dispatch({
        type: EReduxActionTypes.GET_COURSE_SUBTITLE_VIMEO_UPLOADED_FAILED,
      });
    }
    return dispatch({
      type: EReduxActionTypes.GET_COURSE_SUBTITLE_VIMEO_UPLOADED_SUCCESS,
      data: res.data,
    });
  };
}