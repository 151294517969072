import { ComponentProps, FC, useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import HeaderChapterExmFormComponent from "../../../../components/formation-course-form/header-chapter-exam-form-component";
import ContentChapterFormComponent from "../../../../components/formation-course-form/content-chapter-form-component";
import ContentTestExamFormComponent from "../../../../components/formation-course-form/content-test-exam-form-component";
import { updatePath } from '../../../../global-state/actions/breadcrumb-actions';
import { clearFormTest, setTypeFormContentCourse, clearChapter } from '../../../../global-state/actions/course-actions';

const ExamChapterFormPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const typeContent = useMemo(() => { return props?.typeContent }, [props?.typeContent]);
    const [title, setTitle] = useState<string>("");

    const changeTitle = async(value: any) => {
        setTitle(value)
    }
 
    return (<>
        <HeaderChapterExmFormComponent changeTitle={changeTitle} />
        {typeContent === 'CHAPTER' ?
            <ContentChapterFormComponent title={title} />
            :
            <ContentTestExamFormComponent title={title} />
        }
    </>)
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({ 
        clearChapter,
        clearFormTest,
        setTypeFormContentCourse,
        updatePath
    }, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    courseForm: state.courseReducer.courseForm,
    listHistory: state.historyReducer.listHistoryBreadCrumb,
    history: ownProps?.history,
    chapterForm: state.courseReducer.chapterForm,
    indexChapter: state.courseReducer.indexChapter,
    typeContent: state.courseReducer.typeContentCourse,
});
export default connect(mapStateToProps, mapDispatchToProps)(ExamChapterFormPage);
