import { ComponentProps, FC, useEffect, useState, useRef, useMemo } from 'react'
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import { connect } from "react-redux";
import "../../translations/i18n";
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getUserById, setConnectedUser, getUserProfileDetails, updateProfile, getActualTab , getCards, loginUser , checkPassword } from '../../global-state/actions/auth-actions';
import { getPaymentSetting } from '../../global-state/actions/finance-actions';
import TextareaUnderlineElement from '../../elements/text-area/text-area-underline-element';
import { ModalIDS } from '../modals/modal-ids';
import { openModal } from '../../global-state/actions/modal-actions';
import axios from "../../helpers/interceptors";
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import { saveActionHistory } from '../../services/history.service';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { getOffer, getContract } from '../../global-state/actions/app-container-actions';
import { deleteContractApi } from '../../services/finance.service';

const FinanceProfileComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    let dateFormat = require('dateformat');
    const { t } = useTranslation();
    const [offer, setOffer] = useState<any>()
    const [contract, setContract] = useState<any>()
    const openRef = useRef(null);
    const openRef1 = useRef(null);
    const openRef2 = useRef(null);
    const [datesNbre, setDatesNbre] = useState<any>()
    const [payedPeriod, setPayedPeriod] = useState<any>()
    const [comment, setComment] = useState<any>("")
    const [commentText, setCommentText] = useState<any>("")
    const [commentEx, setCommentEx] = useState<any>("")
    const [showP, setShowP] = useState(false);
    const [password, setPassword] = useState('');
    const [msg,setMsg]=useState('')
    const offerBusinessId = process.env.REACT_APP_OFFER_ID_BUSINESS
    const offerHsiId = process.env.REACT_APP_OFFER_ID_HSI
    let history = useHistory()
    let schoolData = useMemo(() => { return props?.connectedSchool }, [props?.connectedSchool]);
    useEffect(() => {
        if (!!props?.subscription && !!props?.subscription?.idOffer) {
            props?.getOffer(props?.subscription?.idOffer)
        }


    }, [props?.subscription])
   
    useEffect(() => {
        if (!!props?.schoolInfo && !!props?.schoolInfo?.id) {
            props?.getContract(props?.schoolInfo?.id, "SCHOOL")
        }


    }, [props?.schoolInfo])

    useEffect(() => {
        if (!!props?.offer) {
            setOffer(props?.offer)
        }
    })
    useEffect(() => {
        if (!!props?.contract && props?.contract?.length >0) {

            setContract(props?.contract[0])
        }

    }, [props?.contract])
    const datesNbreHandler = () => {
  
            if(!!props?.contract[0]?.contractDetails?.contractDuration?.endAt  && new Date(props?.contract[0]?.contractDetails?.contractDuration?.endAt ).getTime() > Date.now()){
                return (new Date(props?.contract[0]?.contractDetails?.contractDuration?.endAt ).getTime() - Date.now()) / (1000 * 3600 * 24)
            }
            else {
                return 0
            }
     
    } 
    useEffect(() => {
        if (!!props?.contract && props?.contract?.length>0) {

            setDatesNbre(datesNbreHandler())
            if (Number.parseFloat(datesNbre) > 31)
            {
                setPayedPeriod(true)
            }else {
                setPayedPeriod(false)
            }

            if (props?.contract[0]?.contractDetails?.contractDuration?.endAt  == null )
            {
                setPayedPeriod(false)
            }

        }
    }, [props?.contract])

    const deleteContract= async(password:any)=>{
        const checkPassword = {
            email: props?.connectedUserInfo?.email,
            password: password
        }
        await props?.checkPassword(checkPassword).then(async(resp:any)=>{

            if(resp.data === true){

                
                setMsg('');
                const object = { ...schoolData }
                object['id'] = props?.schoolInfo?.id;
                object['enabled'] = "Disabled";
                object['creationDate'] = props?.connectedSchool?.creationDate
     
                await deleteContractApi(props?.contract[0]?.contractIdentifier?.contractReference, "CANCELLED", props?.schoolInfo?.id, object,props?.paymentSchoolInfo?.paymentEngine).then(async(respp:any)=>{
                    (openRef as any).current?.click()
                    await props?.openModal({idModal: ModalIDS.SUCCESS_MODAL_WITH_BUTTON, size: "md", buttonText: t('returnToOffer'), lines: [t("subscriptionCancelled")]});
                
                })
          }else{
                setMsg(t('verifyPassword'))
            }
            
        })
      
    }
    const offerFeesHandler = () => {
        if(offer?.pricings?.filter((a: any) => a?.period === "MONTH")[0]?.price === 0  || offer?.pricings?.filter((a: any) => a?.period === "MONTH")[0]?.price === undefined ){
            return t('gratuit')
        } else {
            if(offer?.pricings[0]?.type==="DYNAMIC"){
                return offer?.pricings[0]?.min + "€ / "+t('perMonthPayedAnually')
            } else {
                return offer?.pricings?.filter((a: any) => a?.period === "MONTH")[0]?.price + "€ / "+t('perMonthPayedAnually')
            }
        }
    }  
    const mailCrm=async(comment:any,comment1:any,comment2:any)=>{
        const object ={
            comment:comment,
            commentPri:comment1,
            commentPl:comment2,
            senderMail:props?.connectedUserInfo?.email,
            firstName:props?.connectedUserInfo?.firstName,
            lastName:props?.connectedUserInfo?.lastName,
            receiverMail:"hello@koors.io"

        }
        await axios.post(process.env.REACT_APP_BASE_URL2 + "person/deleteSubscription" ,object)
        
    }
    const goAction = async () => {
        props?.clearHistoryArray()
        props?.redirect(t("addNewPaymentMethod"), `${returnStartPathUrl()}/param`)
         history.push(`${returnStartPathUrl()}/customer/add`)


    }
    const goTo = async () => {
        props?.clearHistoryArray()
        //props?.redirect(t("offersList"), `${returnStartPathUrl()}/param`)
        await saveActionHistory("koors_upgrade_settings",props?.connectedUser?.idPerson,props?.connectedUser?.idSchool)
        history.push(`${returnStartPathUrl()}/list`)


    }
    useEffect(() => {
        if (!!props?.connectedUser?.id ) {
            props?.getPaymentSetting(props?.connectedUser?.id, "CUSTOMER").then(async (resp: any) => {
                if(!! resp?.data && !!resp?.data?.engineId)
                { props?.getCards(resp?.data?.engineId)}
            })
        }
    }, [props?.schoolInfo])

    

    return (<>

        <>


            <div className='w-100 d-flex flex-column p-4'>
                <div className='d-flex flex-column' >
                    <span className="body-xl bold neutral-3 mb-3">{t('Votreoffre')}</span>
                    <span className="H4-Subtitle black-800">{offer?.label}</span>
                </div>
                {offer?.label!==process.env.REACT_APP_OFFER_LABEL_FREE && <hr className="hr-nav my-3" />}
                {offer?.label!==process.env.REACT_APP_OFFER_LABEL_FREE && <div className='d-flex flex-column' >
                    <span className="body-xl bold neutral-3 mb-3" >{t('Fraisdeloffre')}</span>
                    {!payedPeriod ?
                    <div>
                          <span className="H4-Subtitle black-800">{offerFeesHandler()}</span>
                    </div> :
                    <div>
                           <span className="H4-Subtitle black-800">{(offer?.pricings?.filter((a: any) => a?.period === "YEAR")[0]?.price ) + "€ / "+t('perYearPayedAnually')}</span>
                     </div> 
                    }
                </div>}
                {offer?.label!==process.env.REACT_APP_OFFER_LABEL_FREE && <hr className="hr-nav my-3" />}
               {offer?.label!==process.env.REACT_APP_OFFER_LABEL_FREE &&
                   ( contract?.contractDetails?.contractDuration?.endAt !=null)&& <>
                             <div className='d-flex flex-column' >
                    <span className="body-xl bold neutral-3 mb-3">{t('Prochainedatedecheancedupaiement')}</span>
                    <div className='d-flex flex-row'>

                        <span className="H4-Subtitle black-800">{contract?.contractDetails?.contractDuration?.endAt  == null ? "-" : dateFormat(contract?.contractDetails?.contractDuration?.endAt, "dd-mm-yyyy")}</span>
                        <span className="body-sm black-800">&nbsp;| {t('remainning')} {Number.parseFloat(datesNbre).toFixed(0)} {t('Days')}</span>
                    </div>
                </div>
                
                     <hr className="hr-nav my-3" /></>
         
               } 
              
    
            {offer?.label!==process.env.REACT_APP_OFFER_LABEL_FREE && <div className='d-flex flex-column' >
                    <div className='d-flex flex-row justify-content-between mb-3'>
                        <span className="body-xl bold neutral-3 " >{t('Modedepayement')}</span>
                        { !!props?.cards && props?.cards?.length ==0 && 
                        <button type="button" className="btn-text outlined black-800" onClick={goAction} >
                            {t('addPaymentMethod')}
                        </button>}
                        {!!props?.cards && props?.cards?.length >0 && 
                        <button type="button" className="btn-text outlined black-800" onClick={()=>{props?.getActualTab("Finanace")}} >
                            {t('changePaymentMethod')}
                        </button>}
                    </div>
                    {props?.cards == undefined  || props?.cards?.length ==0&& 
                     <span className="H4-Subtitle black-800">{t('noPaymentMethodAdded')}</span>}
                         {props?.cards?.length>0 && 
                     <span className="H4-Subtitle black-800 mt-3">***** ***** ***** ***** ({props?.cards[0]?.last4})</span>}
                </div>}
                <hr className="hr-nav my-3" />
         
                <div className='d-flex flex-column mt-2' >
                    <div className='d-flex flex-row align-items-center justify-content-between'>
                        <button type="button" className="btn-text outlined danger red-800" data-bs-toggle="modal" data-bs-target="#firstStep" data-bs-dismiss="modal" >
                        {t('Annulermonabonnement')}
                        </button>
                        { offer?.id == offerBusinessId  || offer?.id == offerHsiId ?
                        <span></span>
                        :
                        <button type="button" className="btn-Secondary large" onClick={(e) => { goTo() }}  >
                        {t('Changerleforfait')}
                        </button> }
                    </div>
                </div>
            </div>

        </>
        <>
            <div className="modal" id="supprime" tabIndex={-1} role="dialog">
                <div className="modal-dialog modal-lg" role="document">

                    <div className="modal-content">
                        <div className="modal-header"
                            style={{ paddingBottom: 0, border: "none" }}>
                            <div className="w-100 d-flex align-items-center justify-content-end py-2 pe-2">

                                <button ref={openRef} className="btn-QuickActions" id='close' data-bs-dismiss="modal" aria-label="Close" style={{ width: '48px', height: '48px', display: 'flex' }}><span className="material-icons">close</span></button>
                            </div>

                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div style={{ marginTop: '2%' }}></div>
                                <div className="col-md-11"></div>

                                <div style={{ marginBottom: '2%' }}></div>
                            </div>
                            <div className="row body-modal-publish">

                                <div className="col-md-8 center" style={{ margin: 'auto' }}>
                                    <div className="row">
                                        <div className="col-md-12 mb-4">
                                            <span className="H3-Headline">{t('leaveMsg')}</span>

                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <span className="body-sm">{t('annulationWarning')} {dateFormat(contract?.contractDetails?.contractDuration?.endAt , "dd-mm-yyyy")}. {t('annulationWarning2')}</span>

                                      </div>
                                        <div className="col-md-12 mb-4">
                                            <div className='notif-warning d-flex flex-row align-items-center mt-2'>
                                                <span className="material-icons Orange-800 me-3" style={{ fontSize: 32 }}>warning</span>
                                                <span className='body-md black-800'> {t('annulationConfirmation')}</span>
                                            </div>
                                            <hr className="hr-nav my-3" />
                                        </div>
                                        <div className="col-md-12  mb-4">
                                            <span className="form-title">{t('enterPasswordtoConfirm')}</span>
                                        </div>
                                        <div className="col-md-12  mb-4">
                                            <label className="form-title">{t('password')}  </label>
                                            <div className="input-form-control">
                                                <input dir="ltr"type={showP ? "text" : "password"} className="form-control input text-default" placeholder={t('password')} value={password} onChange={(event) => { setPassword(event.target.value) }} />
                                                {!showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                                                {showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => setShowP(!showP)}>visibility</span>}
                                                {!showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                                                {showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility</span>}
                                            </div>
                                            <span className='error-input mt-1'>{msg}</span>
                                        </div>
                                        <div className="d-flex flex-row justify-content-end">
                                            <button type="button" className="btn-Secondary large " data-bs-dismiss="modal" aria-label="Close" style={{ height: 48, width: 102 }}>
                                            {t('annuler')}
                                            </button>

                                            <button type="button" className="btn-Primary large danger ml-2" style={{ height: 48 ,marginLeft:10}} onClick={() => { deleteContract(password) }}  >
                                               {t('cancelSubscription')}
                                            </button>
                                            <button type="button" className="btn-Primary large danger ml-2"  style={{ visibility:'hidden'}} ref={openRef2} data-bs-toggle="modal" data-bs-target="#supprimeOk" data-bs-dismiss="modal"  >
                                             
                                            </button>
                                        </div>
                                        <div className="mt-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

        <>
            <div className="modal" id="firstStep" tabIndex={-1} role="dialog">
                <div className="modal-dialog modal-lg" role="document">

                    <div className="modal-content">
                        <div className="modal-header"
                            style={{ paddingBottom: 0, border: "none" }}>
                            <div className="w-100 d-flex align-items-center justify-content-end py-2 pe-2">

                                <button ref={openRef1} className="btn-QuickActions" id='close' data-bs-dismiss="modal" aria-label="Close" style={{ width: '48px', height: '48px', display: 'flex' }}><span className="material-icons">close</span></button>
                            </div>

                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div style={{ marginTop: '2%' }}></div>
                                <div className="col-md-11"></div>

                                <div style={{ marginBottom: '2%' }}></div>
                            </div>
                            <div className="row body-modal-publish">

                                <div className="col-md-8 center" style={{ margin: 'auto' }}>
                                    <div className="row">
                                        <div className="col-md-12 mb-4">
                                            <span className="H3-Headline">{t('cancelSubscription')}</span>

                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <span className="body-sm">{t('cancelationReasons')}</span>

                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <span className="form-title">{t('whyuCanceling')}*</span>

                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <div className=" select-form-register mb-4" style={{ width: '100%', marginRight: 8 }} >
                                                <select className=" select-city-register"  style={{ height: 48 }} value={comment} onChange={(e: any) => { setComment(e.target.value) }} >
                                                    <option value="" >{t('selectLowerCase')}</option>
                                                    <option value="Il manque une fonctionnalité que je veux">{t('missingFonctionality')}</option>
                                                    <option value="Je pense que c'est trop cher">{t('expensive')}</option>
                                                    <option value="Je n'utilise plus koors">{t('notInteressted')}</option>
                                                    <option value="Je passe à une autre platforme">{t('movingToAnotherPlatform')}</option>
                                                    <option value="Autre">{t('otherChoice')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        {comment == "Autre" && <div className="col-md-12 mb-4">
                                            <span className="form-title">{t('preciser')}*</span>
                                            <div className="w-100 me-1 d-flex align-items-center mt-2">
                                                <TextareaUnderlineElement style={true} placeholder={t('votreAvis')} value={commentText} onChange={(value: string) => setCommentText(value)} id={1} />
                                            </div>
                                        </div>}

                                        <div className="col-md-12 mb-4">
                                            <span className="H4-Subtitle ">{t('wantToTellUsMore')}</span>
                                            <div className="w-100 me-1 d-flex align-items-center mt-2">
                                                <TextareaUnderlineElement style={true} placeholder={t('votreAvis')} value={commentEx} onChange={(value: string) => setCommentEx(value)} id={1} />
                                            </div>
                                        </div>

                                        <div className="d-flex flex-row justify-content-end">
                                            <div className='mr-2'>
                                                <button type="button" className="btn-Secondary large " data-bs-dismiss="modal" aria-label="Close" style={{ height: 48, width: 102 }}>
                                                    {t('Cancel')}
                                                </button>
                                            </div>
                                            <div>
                                                <button type="button" className="btn-Primary large  ml-2" style={{ height: 48, width: 134 ,marginLeft:10 }} data-bs-toggle="modal" data-bs-target="#supprime" data-bs-dismiss="modal" disabled={comment==='' ||commentEx===''} onClick={()=>{mailCrm(comment,commentText,commentEx)}}>
                                                    {t('continue')}
                                                </button></div>
                                        </div>
                                        <div className="mt-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    </>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    offer: state.schoolInfoReducer.offer,
    subscription: state.schoolInfoReducer.subscription,
    contract: state.schoolInfoReducer.contract,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    connectedSchool: state.authReducer.connectedSchoolInfo,
    cards: state.authReducer.cards,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getUserProfileDetails,
    updateProfile,
    getUserById,
    setConnectedUser, getOffer, getContract,
    loginUser,
    checkPassword,
    openModal,
    getPaymentSetting,
    getCards,
    getActualTab,
    redirect, clearHistoryArray

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinanceProfileComponent);


